import React from 'react';
import homeScreenImage from './SuperStackSolitaire/superstacksolitaire_homescreen.png';
import pryamidScreenImage from './SuperStackSolitaire/superstacksolitaire_pyramid.png';
import klondikeImage from './SuperStackSolitaire/superstacksolitaire_klondike.png';
import cowboy_casino from './ProjectCovers/cowboy_casino.png';
import airunnerHomeScreen from './AIRunner/airunner_homescreen.png';
import airunnerHomeScreen2 from './AIRunner/airunner_2.png';
import airunnerHomeScreen3 from './AIRunner/airunner_3.png';
import cc_blackjack from './CowboyCasino/blackjack.png';
import cc_poker from './CowboyCasino/poker.png';
import cc_mainmenu from './CowboyCasino/mainmenu.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faItchIo } from '@fortawesome/free-brands-svg-icons'
import './imagebutton.css';
import './modal.css';
import './projects.css';
import superstacksolitairecover from './ProjectCovers/superstacksolitaire.png';
import airunnerCover from './ProjectCovers/airunner.png';
const projects = [
  {
    title: 'Cowboy Casino',
    description: 'Play blackjack, poker and slots against some cheatin\' cowbpokes.',
    images: [{
      src: cc_mainmenu,
      title: "Home Screen",
    },
    {
      src: cc_poker,
      title: "Poker",
    },
    {
      src: cc_blackjack,
      title: "Blackjack",
    }],
    link: "https://capsizegames.itch.io/cowboy-casino",
    releaseDate: 'December 12, 2024',
    platforms: ['PC'],
    tools: ['Unity3D', 'C#', 'LMMS', 'Aesprite'],
    status: 'Released',
    publisher: 'Capsize Games',
    coverImage: cowboy_casino
  },
  {
      title: 'AI Runner',
      description: 'Stable Diffusion and Mistral-7b offline on your own hardware.',
      images: [{
        src: airunnerHomeScreen,
        title: "Home Screen",
      },
      {
        src: airunnerHomeScreen2,
        title: "Chatbot",
      },
      {
        src: airunnerHomeScreen3,
        title: "Art tools",
      }],
      link: "https://capsizegames.itch.io/airunner",
      releaseDate: '2022',
      platforms: ['PC'],
      tools: ['Python', 'Pyside6', "Huggingface"],
      status: 'Released',
      publisher: 'Capsize Games',
      coverImage: airunnerCover
  },
  {
    title: 'Super Stack Solitaire',
    description: 'Pyramid and Klondike solitaire featuring casual and arcade modes with an 8-bit aesthetic following a modified NES spec.',
    images: [{
      src: homeScreenImage,
      title: "Home Screen",
    },
    {
      src: pryamidScreenImage,
      title: "Pyramid",
    },
    {
      src: klondikeImage,
      title: "Klondike",
    }],
    link: "https://capsizegames.itch.io/super-stack-solitaire",
    releaseDate: 'June 05, 2022',
    platforms: ['PC'],
    tools: ['Unity3D', 'C#', 'FamiTracker', 'Aesprite'],
    status: 'Released',
    publisher: 'Capsize Games',
    coverImage: superstacksolitairecover
  },
];

class Projects extends React.Component {
  constructor() {
    super();
    this.state = {
      coverImage: null
    };
  }

  closeModal = () => {
    this.setState({ coverImage: null });
  }

  renderCoverImage = () => (this.state.coverImage ? (
    <div className="modal coverimage" onClick={this.closeModal}>
      <span onClick={this.closeModal} className="close">&times;</span>
      <img alt={"product cover"} className="modal-content" src={this.state.coverImage} />
      <div className="caption"></div>
    </div>
  ) : null)

  showImage = (image) => {
    this.setState({coverImage: image});
  }

  renderProject = (data, projectindex) => (
    <div className="project" key={`project-key-${projectindex}`}>
      <h3>
        {data.title}
      </h3>
      <p className="description">{data.description}</p>
      <div className="meta-data">
        {data.link ? (
          <div className="marketplaces">
            <small>marketplaces</small>
            {data.link ? (<a href={data.link}><FontAwesomeIcon icon={faItchIo} /></a>) : null}
          </div>
        ) : null }
        <table className="details">
          <tr>
            <td><strong>Status</strong></td>
            <td>{data.status}</td>
          </tr>
          {data.releaseDate ? (
            <tr>
              <td><strong>Release date</strong></td>
              <td>{data.releaseDate}</td>
            </tr>
          ) : null}
          <tr>
            <td><strong>Platforms</strong></td>
            <td>{data.platforms.join(', ')}</td>
          </tr>
          <tr>
            <td><strong>Tools</strong></td>
            <td>{data.tools.join(', ')}</td>
          </tr>
        </table>
      </div>
      <div className="images">
        {data.images.map((image, index) => (
        <div key={`image-key-${index}`} className="image-container" onClick={() => this.showImage(image.src)}>
          <img alt={"project"} style={{ width: 256, height: 144 }} src={image.src} />
          <div className="image-text-container">
            <div className="image-text">{image.title}</div>
          </div>
        </div>))}
      </div>
      {this.renderCoverImage()}
    </div>
  )

  selectProject = (data, projectIndex) => {
    this.setState({ project: data, projectIndex: projectIndex });
  }

  renderProjectLink = (data, projectindex) => (
    <div className="project" key={`project-key-${projectindex}`}>
      <img alt={"project cover"} onClick={() => this.selectProject(data, projectindex)} className="coverimage" src={data.coverImage} />
    </div>
  )

  render() {
    return (
      <div className="section-content projects">
        {this.state.project ? this.renderProject(this.state.project, this.state.projectIndex) : projects.map(this.renderProjectLink)}
      </div>
    )
  }
}

export default Projects;
