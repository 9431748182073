import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

class Contact extends React.Component {
  constructor() {
    super();
    this.state = {
    };
  }

  render() {
    return (
      <div className="section-content">
        <div className="contact content">
          <h2><FontAwesomeIcon icon={faEnvelope} /> Contact</h2>
          Contact us at <a href="mailto:capsizegames@fastmail.com">capsizegames@fastmail.com</a>
        </div>
      </div>
    )
  }
}

export default Contact;